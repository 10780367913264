
export function OutlinedBtn({ title, handleClick }: { title: any, handleClick: Function }){

    return(
        <span 
        onClick={() => handleClick()}
        className="noselect d-flex justify-content-center align-items-center outlined-btn">
            { title }
        </span>
    )
}