import { AcceptedPlayerRequestCard } from "component/cards/AcceptedPlayerRequestCard";
import { Loading } from "component/loader/Loading";
import { filterRequests } from "helperFunctions/getDataFiltered";
import { useUserSubmission } from "hooks/useUserSubmission";
import { AdminDashboard } from "layout/AdminDashboardLayout";
import React from "react";
import { activeNavBarButton, searchField } from "store/store";

export function AcceptedDashboard() {
  activeNavBarButton.value = "ACCEPTED";

  const { data, error, loading, setIsDataChanged, setLoading } = useUserSubmission("ACCEPTED");
  let requestsData = filterRequests(searchField.value, data);
  if(error){
      return(
          <div>
              {error.message}
          </div>
      )
  }
  return (
    <AdminDashboard>
      {loading ? (
        <Loading />
      ) : (
        requestsData &&
        requestsData.map((eachRequest: any) => {
          return (
            <React.Fragment key={eachRequest.submissionData.submissionId}>
              <AcceptedPlayerRequestCard requestData={eachRequest} setIsDataChanged={setIsDataChanged} setLoading={setLoading} />
              <div className="height-17"></div>
            </React.Fragment>
          );
        })
      )}
    </AdminDashboard>
  );
}
