import { FilledBtn } from "component/buttons/Fillterbtn";
import { useNavigate } from "react-router-dom";

export function Error(){
    const navigate = useNavigate();
    return (
        <div className="theme-font-light fw-bold d-flex flex-column align-items-center justify-content-center gap-3">
            <div
            style={{fontSize: "72px"}}
            >404</div>
            <div className="text-38 fw-lighter">Page Not Found</div>
            <FilledBtn handleClick={() => navigate("/")} title="DASHBOARD"  />
        </div>
    )
}