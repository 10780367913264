import { firestore } from "services/firebaseConfig";


export async function isTokenAlreadyClaimed(uid: string, setIsClaimed: any, setLoading: any){
        const data = await firestore.collection("dreamhack_gamepass_tokens").where("belongsTo", "==", uid).get();
        if (data.empty){
        setIsClaimed(false)
        }
        else{
            setIsClaimed(true)
        }
        setLoading(false)
    }