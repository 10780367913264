import { FilledBtn } from "component/buttons/Fillterbtn";
import { useNavigate } from "react-router-dom";
import { deleteWinner, setRaffleWinner } from "services/raffle/getRandomUser";
import { useState, useEffect } from "react"
import { Rafflebtn } from "component/buttons/Raffflebtn";
import { OutlinedBtn } from "component/buttons/OutlinedBtn";
import { Loading } from "component/loader/Loading";

export function RaffleCard(
    {userName, uid, handleRaffleChoose, adminUid, day, winnerNumber, isAlreadyConfirmed, prize}: 
    {userName: string | undefined, uid: string | undefined, handleRaffleChoose: any, adminUid: string, day: string, winnerNumber: 1 | 2, isAlreadyConfirmed: boolean, prize: string}
    ){
    const navigate = useNavigate()
    const [winnerConfirmed, setWinnerConfirmed] = useState(isAlreadyConfirmed);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        setWinnerConfirmed(isAlreadyConfirmed);
    }, [isAlreadyConfirmed])
    //fn to set Raffle Winner
    const handleConfirmWinner = () => {
        if(uid && userName){
            setRaffleWinner(uid, adminUid, day, winnerNumber, userName, prize)
            setWinnerConfirmed(true);
        }
    }

    const handleDelete = async () => {
        if(uid){
            setDeleteLoading(true);
            await deleteWinner(uid);
            window.location.reload();
        }
    }
    return(
        <div className="raffle-card d-flex justify-content-between align-items-center">
            <div className="h-100 flex-fill d-flex align-items-center gap-2 ">
                {uid ? 
                <>
                    <h4 className="fw-bolder text-lenovo"
                    onClick={() => navigate(`/user/${uid}`)}
                    > {userName} </h4>
                    <h5>Won {prize.toUpperCase()} </h5>
                </> : 
                <>
                    <h6>Choose a winner for {prize.toUpperCase()}</h6>
                </>
             }
            </div>
            <div className="d-flex flex-column gap-1">
                {uid && <Rafflebtn title="Confirm Winner" disabled={winnerConfirmed} handleClick={() => handleConfirmWinner()} />}
                {!winnerConfirmed && <FilledBtn title={uid ? "Choose new winner" : "Shuffle"} handleClick={() => handleRaffleChoose()} />}
                {winnerConfirmed && <OutlinedBtn title={deleteLoading ? <> <Loading /> {" "} Deleting... </> : "Delete Winner"} handleClick={() => handleDelete()} />}
            </div>
        </div>
    )
}