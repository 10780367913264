import { functions } from "services/firebaseConfig"
import { TypegetUserRequestsWhenScanWithQR } from "types/cloudfunctionResponse/TypegetUserRequestsWhenScanWithQR";


export const getUserRequestsWhenScanWithQR = async (currUserId: string, setData: React.Dispatch<React.SetStateAction<TypegetUserRequestsWhenScanWithQR | undefined>>, setError: React.Dispatch<any>) => {
    try{
        const getData = functions("getUserRequestsWhenScanWithQR")
        const response = (await getData({currUserId})).data as TypegetUserRequestsWhenScanWithQR;
        setData(response);
    } catch (error) {
        setError(error);
    }
}