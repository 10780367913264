import { Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "services/auth/logoutTheUser";

export function OffCanvaGames({ setShow, show, fullName } : { setShow: React.Dispatch<React.SetStateAction<boolean>>, show: boolean, fullName: string}) {
    const navigate = useNavigate()
    const handleClose = () => {
        setShow(false)
    }
    const handleClick = (navPath: string) => {
        navigate(navPath);
        handleClose();
    }
  return (
    
    <Offcanvas
    className="off-canvas d-flex flex-column align-items-center"
    placement="end"
    show={show} onHide={() => handleClose()}
    >
        <div 
        className="header-menu d-flex justify-content-center align-items-center align-self-end"
        onClick={() => setShow(false)}>
            <CrossSvg />
        </div>
        <div className="height-30"></div>
        <span className="text-24 theme-font-light fw-bold">
            {fullName}
        </span>
        <div className="height-30"></div>
            <span
             onClick={() => handleClick("/")}
             className="offcanvas-tiles">Dashboard</span>
        <div className="height-30"></div>
            <span
             onClick={() => handleClick("/scan-user")}
             className="offcanvas-tiles">Scan QR</span>
        <div className="height-30"></div>
            <span
                onClick={() => handleClick("/daily-raffle")}
                className="offcanvas-tiles">Daily Raffle</span>
        <div className="height-30"></div>
            <span
                onClick={() => handleClick("/stats")}
                className="offcanvas-tiles">Participants Stats</span>
        <div className="height-30"></div>
        <div
        onClick={() => logoutUser()}
        className="aling-self-end theme-font-light text-16">Log Out</div>
  </Offcanvas>
  );
}

const CrossSvg = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="1.19141" y1="18.5233" x2="18.162" y2="1.55272" stroke="#F1F5FF" strokeWidth="2" strokeLinecap="round"/>
<line x1="1.41421" y1="1" x2="18.3848" y2="17.9706" stroke="#F1F5FF" strokeWidth="2" strokeLinecap="round"/>
</svg>
