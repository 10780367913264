import { auth, functions } from "../firebaseConfig";
import firebase from "firebase";

export async function loginWithEmailAndPassword(email: string, password: string, setLoggedIn: React.Dispatch<React.SetStateAction<Promise<firebase.auth.UserCredential> | undefined>>, setError: React.Dispatch<any>, setLoading:  React.Dispatch<React.SetStateAction<boolean>>){
    try{
        const logIn = functions("loginAdminUser");
        
        const promise = await (await logIn({email, password})).data
        
        if (promise.err || promise.message === "No such admin user"){
            throw promise.message 
        }
        await auth.signInWithCustomToken(promise.token)

        // await auth.currentUser!.getIdToken().then((result: any) => {
        //     const token = result.token;
        //     console.log(token);
            
        // });
        setLoggedIn(promise)
        setError(undefined)
        setLoading(false)
    } catch (err){
        setError(err)
        setLoading(false)
    }
}

export const verifyEmailAddress = async (email: string, setVerified: React.Dispatch<any>) => {
    try{
        const doesEmailExist = await auth.fetchSignInMethodsForEmail(email);
        if(doesEmailExist.length === 0){
            setVerified(false);
        }else {
            setVerified(true);
        }
    } catch (err){
        setVerified(false); 
    }
}


// import { auth } from "../firebaseConfig";

// export async function loginWithEmailAndPassword(email: string, password: string, setLoggedIn: React.Dispatch<any>, setError: React.Dispatch<any>){
//     try{
//         const promise = await auth.signInWithEmailAndPassword(email, password);
//         setError(undefined)
//         setLoggedIn(promise)
//     } catch (err) {
//         setError(err)
//     }
    
// }