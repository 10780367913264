import { Image } from "react-bootstrap";
import { OutlinedToFilledButton } from "component/buttons/OutlinedToFilledButton";
import { setChallengeTypeIwinner } from "services/challenges/setChallengeType1Winner";
import { useState } from "react";
import { currentActiveAdmin } from "store/store";
export function UserChallengeCard({ challenge }: any){
    const { name, game, logoUrl, endDate, isAccepted, junctionProfileChallengeId } = challenge; 
    const seconds = new Date().getTime() / 1000;
    const endsInSeconds = endDate._seconds - seconds;
    const [addWinnerError, setAddWinnerError] = useState<any>(undefined);
    const [isWinner, setIsWinner] = useState(isAccepted);
    function endsInSecondsToTime(seconds: number){
        // if time is less than 1 min
        if(seconds < 0){
            return "ENDED"
        }
        if(seconds/60 < 1){
            return `${Math.ceil(seconds)} seconds`
        }

        //if time is less then 1 hr, then show result in minutes
        if(seconds/3600 < 1){
            return `${Math.ceil(seconds/60)} mins`
        } 

        // if time is less than 24hrs, then show result in hrs
        if(seconds/86400 < 1){
            return `${Math.ceil(seconds/3600)} hrs`
        } 
        else {
            return `${Math.ceil(seconds/86400)} days`
        }
    }

    const handleSetWinner = () => {
        setChallengeTypeIwinner(junctionProfileChallengeId, setAddWinnerError, name,  currentActiveAdmin.value, true);
        setIsWinner(true);
    }
    
    const handleRemoveWinner = () => {
        setChallengeTypeIwinner(junctionProfileChallengeId, setAddWinnerError, name,  currentActiveAdmin.value, false);
        setIsWinner(false);
    }
    
    return (
        <div className="user-info-container d-flex justify-content-between align-items-center">
            {addWinnerError ? <>
                <span className="fw-bold text-24 text-center theme-font-light">{addWinnerError}</span>
            </>
            :
            <>
            <div className="d-flex gap-2 align-items-center">
                <Image
                className="userChallengeCard-logo"
                src={logoUrl}
                />
                <div className="d-flex flex-column gap-1 align-items-start theme-font-light ">
                    <span className="fw-bold text-24">{name}</span>
                    <span className="text-muted text-14">{game}</span>
                    <span className="text-16">Ends in: {endsInSecondsToTime(endsInSeconds)}</span>
                </div>
            </div>
            <div className="d-flex flex-column gap-2">
            <OutlinedToFilledButton title="ADD WINNER" title2={<Winner />} handleClick={() => handleSetWinner()} initState={isWinner} />
            {isWinner && <OutlinedToFilledButton title="REMOVE WINNER" title2={<Winner />} handleClick={() => handleRemoveWinner()} initState={!isWinner} />}
            </div>
            </>}
    </div>
    )
}

const Winner = () => <div className="d-flex gap-2 align-items-center">
<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.23245 14.656L0.832963 9.17387C0.675314 8.97541 0.599394 8.72425 0.620714 8.47169C0.642034 8.21913 0.758984 7.98425 0.947671 7.81503C1.04 7.73431 1.14777 7.6732 1.26443 7.6354C1.3811 7.59759 1.50422 7.58389 1.62635 7.59511C1.74847 7.60633 1.86704 7.64225 1.97486 7.70068C2.08268 7.75911 2.1775 7.83884 2.25358 7.93503L6.65395 13.4163C6.98042 13.824 6.93013 14.4346 6.53836 14.7751C6.44597 14.8558 6.33816 14.9169 6.22146 14.9546C6.10476 14.9923 5.98161 15.006 5.85949 14.9946C5.73737 14.9833 5.61881 14.9473 5.51102 14.8888C5.40324 14.8303 5.30846 14.7505 5.23245 14.6543V14.656Z" fill="#F1F5FF"/>
<path d="M5.40051 14.7752C5.21091 14.6069 5.09279 14.3724 5.07032 14.1198C5.04785 13.8672 5.12272 13.6156 5.27963 13.4164L15.741 0.345883C15.8167 0.249312 15.9112 0.169134 16.0188 0.110219C16.1264 0.0513034 16.2449 0.0148803 16.3671 0.00316193C16.4892 -0.00855648 16.6124 0.00467431 16.7293 0.0420507C16.8462 0.0794271 16.9542 0.140169 17.0469 0.220587C17.4387 0.560299 17.4934 1.1709 17.1678 1.57943L6.70553 14.6544C6.62985 14.7509 6.53532 14.8311 6.42769 14.89C6.32007 14.9489 6.20159 14.9854 6.07945 14.9971C5.95732 15.0088 5.83407 14.9956 5.71721 14.9582C5.60034 14.9208 5.4923 14.8601 5.39963 14.7797V14.7744L5.40051 14.7752Z" fill="#F1F5FF"/>
</svg>
WINNER
</div>