import { themeColors } from "assets/themeColors"
import { useRef } from "react";
export function TimeEditor({ minutes, seconds, millies, setMillies, setMinutes, setSeconds }: 
    {
        minutes: number; 
        seconds: number | false; 
        millies: number | false;
        setMinutes: React.Dispatch<React.SetStateAction<number>>; 
        setSeconds: React.Dispatch<React.SetStateAction<number | false>>;
        setMillies: React.Dispatch<React.SetStateAction<number | false>>; 
    }
    ){ 
    const inputRef = useRef<any>();
    
    return(
        <div 
        style={{border: `1px solid ${themeColors.lenovo}`}}
        className="time-editor d-flex justify-content-between">
            <span
            style={{color: themeColors.font}}
            className="d-flex align-items-center"
            > 
                <input
                ref={inputRef}
                className="score-value-field text-center"
                style={{width: seconds !== false ? "" : "160px"}}
                type="number"
                value={minutes} 
                onChange={(e) => {
                    if(+e.target.value > -1) setMinutes(+e.target.value)} } /> 
                {
                    seconds !== false &&
                    <>
                    :
                    <input
                    min={0}
                    className="score-value-field text-center" type="number" value={seconds} onChange={(e) => { 
                        if(+e.target.value < 60 && +e.target.value > -1) setSeconds(+e.target.value)
                    }} />
                    :
                    </>
                }
                {
                    millies !== false &&
                    <input 
                    className="score-value-field text-center" 
                    type="number" value={millies} 
                    onChange={(e) => { 
                        if(+e.target.value < 1000 && +e.target.value >= 0) setMillies(+e.target.value)
                    }} />
                }
            </span>
            <span onClick={() => {
                inputRef.current.focus();
                }}>
                <EditBtnSvg />
            </span>
        </div>
    )
}

const EditBtnSvg = () => <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.03359 18.0209L19.5195 5.53499C20.1627 4.8918 20.1627 3.84877 19.5195 3.20557L18.081 1.76707C17.4378 1.12387 16.3947 1.12387 15.7515 1.76707L3.26567 14.2486C3.00926 14.505 2.82238 14.8266 2.73546 15.1786L1.56641 19.7158L6.10356 18.5467C6.45558 18.4555 6.77718 18.2729 7.03359 18.0165V18.0209Z" stroke="#D1D5E0" strokeWidth="1.56454" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.5742 2.94092L18.3421 6.71319" stroke="#D1D5E0" strokeWidth="1.56454" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

