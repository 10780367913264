export const filterRequests = (searchValue: string, data: any) => {
    if(searchValue){
        const newFilteredData: any = []
        data.forEach((eachValue: any) => {
            const { profile } = eachValue
            if(profile.slug.toLowerCase().match(searchValue.toLowerCase())){
                newFilteredData.push(eachValue);
            }
        })
        return newFilteredData;
    } else return data
}