import { useRef, useState, useEffect } from "react"
import { FilledBtn } from "component/buttons/Fillterbtn";
import { EmailField } from "component/inputField/EmailField";
import { PasswordField } from "component/inputField/PasswordField";
import { LoginLayout } from "layout/LoginLayout";
import { useNavigate } from "react-router-dom";
import { loginWithEmailAndPassword } from "services/auth/loginUserWithEmailAndPasssword";
import firebase from "firebase"
import { Loading } from "component/loader/Loading";

export function Login(){
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState<Promise<firebase.auth.UserCredential> | undefined>(undefined);
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);
    
    //Login the user if both are true
    const emailRef = useRef("");
    const passwordRef = useRef("");
    
    useEffect(() => {
        if(isLoggedIn && !error){
            navigate("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, error])

    const handleLogin = () => {
        setIsLoading(true);
        loginWithEmailAndPassword(emailRef.current, passwordRef.current, setIsLoggedIn, setError, setIsLoading)
    }
    const handleGoBack = () => {
        navigate("/")
    }
    return(
        <LoginLayout>
             <span
            className="fw-bold text-38 theme-font-light "
            >Log in with given email and password</span>
            <div className="height-53"></div>
            <EmailField emailRef={emailRef} />
            <div className="height-15"></div>
            <PasswordField passwordRef={passwordRef} />
            <div className="height-53"></div>
            <div className="d-flex justify-content-between align-items-center login-buttons ">
                <span className="text-muted" onClick={() => handleGoBack()}>GO BACK</span>
                <FilledBtn title={loading ? <Loading /> : "Log In"} handleClick={() => handleLogin()} />
            </div>
            {error && <span className="theme-font-light text-16 text-center mt-5">{error.message || error}</span>}
        </LoginLayout>
    )
}