import { Gamepass, Legion, Intel } from "assets/logos/Logos";
import { currentActiveAdmin } from "store/store";
import { OffCanvaGames } from "./OffCanvas";
import { useState} from 'react'


export function Header(){
    const [show, setShow] = useState(false)
    
    const adminName = currentActiveAdmin.value ? (currentActiveAdmin.value.name !== null ? currentActiveAdmin.value.name : currentActiveAdmin.value.email) : "Admin"
    return(
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
                <Legion />
                <div className="width-24"></div>
                <Intel />
                <div className="width-24"></div>
                <Gamepass />
            </div>
            <div className="d-flex gap-4 align-items-center">
                <span className="text-16 theme-font-light">{adminName}</span>
                <div
                onClick={() => setShow(true)}
                className="header-menu d-flex justify-content-center align-items-center ">
                    <HamMenuSvg />
                </div>
                <OffCanvaGames setShow={setShow} show={show}  fullName={adminName} />
            </div>
        </div>
    )
}

const HamMenuSvg = () => <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="1" y1="1" x2="25" y2="1" stroke="#F1F5FF" strokeWidth="2" strokeLinecap="round"/>
<line x1="5.16016" y1="9.31982" x2="25.0002" y2="9.31982" stroke="#F1F5FF" strokeWidth="2" strokeLinecap="round"/>
<line x1="1" y1="17.6401" x2="25" y2="17.6401" stroke="#F1F5FF" strokeWidth="2" strokeLinecap="round"/>
</svg>

