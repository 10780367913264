import { useState } from "react"
import { themeColors } from "assets/themeColors"
import { FilledBtn } from "component/buttons/Fillterbtn"
import { useNavigate } from "react-router-dom"
import { searchField } from "store/store";

export function SearchBarAndQR (){
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState("");

    const handleChange = (value: string) => {
        searchField.value = value;
        setSearchInput(value);
    }
    return(
        <div className="d-flex justify-content-between">
        <div
        className="input-search-field-container d-flex justify-content-center align-items-center gap-1"
        style={{background: themeColors.fg}} 
        >
            <SearchSvg />
            <input
            value={searchInput}
            onChange={(e) => handleChange(e.target.value)}
            className="input-search-field theme-font-light"
            placeholder="Search username..." type="text" />
        </div>
        <div>
            
            <FilledBtn title={<Content />} handleClick={() => navigate('/scan-user')} />
        </div>
        </div>
    )

}


const SearchSvg = () => <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.7433 6.77692C12.7433 7.91964 12.4044 9.03671 11.7696 9.98686C11.1347 10.937 10.2324 11.6776 9.17667 12.1149C8.12094 12.5523 6.95924 12.6667 5.83846 12.4438C4.71768 12.221 3.68816 11.6707 2.88007 10.8628C2.07199 10.0548 1.52164 9.02534 1.29861 7.90459C1.07559 6.78383 1.1899 5.62212 1.6271 4.56633C2.0643 3.51055 2.80475 2.60811 3.75483 1.97314C4.7049 1.33817 5.82192 0.999172 6.96465 0.999023C7.72347 0.998925 8.47489 1.1483 9.17598 1.43862C9.87707 1.72895 10.5141 2.15453 11.0507 2.69106C11.5873 3.2276 12.013 3.86459 12.3034 4.56564C12.5938 5.2667 12.7433 6.01809 12.7433 6.77692V6.77692Z" stroke="#D1D5E0" strokeWidth="1.49686" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.1903 13.9999L11.0469 10.8564" stroke="#D1D5E0" strokeWidth="1.49686" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const Content = () => <div className="d-flex gap-2">
<QR />
SCAN QR CODE
</div>


const QR = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1 0C0.447715 0 0 0.447716 0 1V6.0588C0 6.61108 0.447716 7.0588 1 7.0588H6.0588C6.61108 7.0588 7.0588 6.61108 7.0588 6.0588V1C7.0588 0.447715 6.61108 0 6.0588 0H1ZM3.11874 2.11762C2.56646 2.11762 2.11874 2.56533 2.11874 3.11762V3.94113C2.11874 4.49342 2.56646 4.94113 3.11874 4.94113H3.94226C4.49455 4.94113 4.94226 4.49342 4.94226 3.94113V3.11762C4.94226 2.56533 4.49454 2.11762 3.94226 2.11762H3.11874Z" fill="#D1D5E0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M17.9414 0C17.3891 0 16.9414 0.447716 16.9414 1V6.0588C16.9414 6.61108 17.3891 7.0588 17.9414 7.0588H23.0002C23.5525 7.0588 24.0002 6.61108 24.0002 6.0588V1C24.0002 0.447715 23.5525 0 23.0002 0H17.9414ZM20.0601 2.11757C19.5079 2.11757 19.0601 2.56529 19.0601 3.11757V3.94109C19.0601 4.49337 19.5079 4.94109 20.0601 4.94109H20.8837C21.436 4.94109 21.8837 4.49337 21.8837 3.94109V3.11757C21.8837 2.56529 21.436 2.11757 20.8837 2.11757H20.0601Z" fill="#D1D5E0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 16.9414C0.447715 16.9414 0 17.3891 0 17.9414V23.0002C0 23.5525 0.447716 24.0002 1 24.0002H6.0588C6.61108 24.0002 7.0588 23.5525 7.0588 23.0002V17.9414C7.0588 17.3891 6.61108 16.9414 6.0588 16.9414H1ZM3.11874 19.0588C2.56646 19.0588 2.11874 19.5065 2.11874 20.0588V20.8823C2.11874 21.4346 2.56646 21.8823 3.11874 21.8823H3.94226C4.49455 21.8823 4.94226 21.4346 4.94226 20.8823V20.0588C4.94226 19.5065 4.49454 19.0588 3.94226 19.0588H3.11874Z" fill="#D1D5E0"/>
<rect x="9.88281" width="2.11764" height="7.0588" rx="1" fill="#D1D5E0"/>
<rect x="16.9414" y="12" width="2.11764" height="7.0588" rx="1" fill="#D1D5E0"/>
<rect x="12" y="9.17627" width="2.11764" height="12" rx="1" transform="rotate(90 12 9.17627)" fill="#D1D5E0"/>
<rect x="24" y="21.8823" width="2.11764" height="8.47056" rx="1" transform="rotate(90 24 21.8823)" fill="#D1D5E0"/>
<rect x="14.1172" y="2.11768" width="2.11764" height="4.23528" rx="1" transform="rotate(90 14.1172 2.11768)" fill="#D1D5E0"/>
<rect x="14.1172" y="12" width="2.11764" height="4.23528" rx="1" transform="rotate(90 14.1172 12)" fill="#D1D5E0"/>
<rect x="24" y="9.88232" width="2.11764" height="4.94116" rx="1" transform="rotate(90 24 9.88232)" fill="#D1D5E0"/>
<rect x="19.0586" y="16.9414" width="2.11764" height="4.94116" rx="1" transform="rotate(90 19.0586 16.9414)" fill="#D1D5E0"/>
<rect x="21.1797" y="12" width="2.11764" height="4.23528" rx="1" transform="rotate(90 21.1797 12)" fill="#D1D5E0"/>
<rect x="4.94141" y="14.1177" width="2.11764" height="4.23528" rx="1" transform="rotate(-180 4.94141 14.1177)" fill="#D1D5E0"/>
<rect x="12" y="14.1177" width="2.11764" height="4.23528" rx="1" transform="rotate(-180 12 14.1177)" fill="#D1D5E0"/>
<rect x="21.1797" y="14.1177" width="2.11764" height="4.23528" rx="1" transform="rotate(-180 21.1797 14.1177)" fill="#D1D5E0"/>
<rect x="24" y="24" width="2.11764" height="4.94116" rx="1" transform="rotate(-180 24 24)" fill="#D1D5E0"/>
<rect x="14.1172" y="24" width="2.11764" height="4.23528" rx="1" transform="rotate(-180 14.1172 24)" fill="#D1D5E0"/>
<rect x="16.2383" y="7.05859" width="2.11764" height="2.11764" rx="1" transform="rotate(90 16.2383 7.05859)" fill="#D1D5E0"/>
<rect x="24" y="14.1177" width="2.11764" height="2.11764" rx="1" transform="rotate(90 24 14.1177)" fill="#D1D5E0"/>
<rect x="9.17969" y="14.1177" width="2.11764" height="2.11764" rx="1" transform="rotate(90 9.17969 14.1177)" fill="#D1D5E0"/>
<rect x="11.293" y="16.9414" width="2.11764" height="2.11764" rx="1" transform="rotate(90 11.293 16.9414)" fill="#D1D5E0"/>
</svg>