import { collectionRef } from "collectionRefs/collectionOperations"
import { FieldValue, firestore } from "services/firebaseConfig";
import { CurrentActiveAdmin } from "store/store";

export const setChallengeTypeIwinner = async (junctionId: string, setError: React.Dispatch<any>, challengeName: string, admin: CurrentActiveAdmin | undefined, win: boolean) => {
    try{
        if(win){
            await collectionRef.junctionProfileChallengeDH.doc(junctionId).update({
                userChallengeData: {
                    rank: 1,
                    points: 1
                }
            })
            await firestore.collection("dreamhack_challenges_logs").add({
                challengeName,
                adminName: admin ? admin.name || admin.email : "unknown",
                created: FieldValue.serverTimestamp(), 
                userId: junctionId.split("_")[0],
            })
        } else {
            await collectionRef.junctionProfileChallengeDH.doc(junctionId).update({
                userChallengeData: {
                    rank: 2,
                    points: 0
                }
            })
        }
    
    } catch (err: any){
           if(err.message === "Missing or insufficient permissions."){
            setError("Failed to update the status!");
        }
    }
}