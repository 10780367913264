import { themeColors } from "assets/themeColors";
import { AcceptBtn } from "component/buttons/Accept";
import { DenyBtn } from "component/buttons/Deny";
import { Image } from "react-bootstrap";
import { TimeEditor } from "./components/TimeEditor";
import { UserProfileDisk } from "./components/UserProfileDisk";
import { timestampToHrsNMinString } from "helperFunctions/timestampToDate";
import { useUserSubmissions } from "component/submissionFunction/UserSubmissionsFunction";
import { UserSummitionRequest } from "types/cloudfunctionResponse/TypeUserSubmissionRequests";

export function PendingPlayerRequestCard({ requestData,
  setIsDataChanged, 
  setLoading
}: {
  requestData: UserSummitionRequest;
  setIsDataChanged: any;
  setLoading: any;
  }) {
  const {finishedChallengeError, profile, submissionData, time, updateSubmissionOnClick} = useUserSubmissions(requestData, setIsDataChanged, setLoading);
  const { minutes, seconds, millies, setMinutes, setSeconds, setMillies } = time;
  return (
    <div className="pending-dashboard-container d-flex flex-column">
      {finishedChallengeError ? (
        <span className="fw-bold text-24 text-center theme-font-light">
          {finishedChallengeError}
        </span>
      ) : (
        <>
          <div className="d-flex align-items-start justify-content-between">
            <div className="d-flex align-items-start justify-content-between">
              <UserProfileDisk
                name={profile.slug}
                image={profile.profileLogoUrl}
                lg={false}
              />
              <div style={{ width: "16px" }}></div>
              <div className="d-flex flex-column">
                <span style={{ color: themeColors.font }} className="text-20">
                  {profile.slug}
                </span>
                <span
                  style={{ color: themeColors.fontSupplemental }}
                  className="text-18"
                >
                  {submissionData.game} / {submissionData.name}
                </span>
                <span
                  style={{ color: themeColors.fontSupplemental }}
                  className="text-16"
                >
                  Submitted at{" "}
                  {timestampToHrsNMinString(submissionData.submittedAt)}
                </span>
              </div>
            </div>
            <div className="d-flex">
              <div style={{ width: "20px" }}></div>
              <div className="d-flex flex-column align-items-start justify-content-between">
                <span style={{ color: themeColors.font }} className="text-18">
                  Value({submissionData.scoreValueType})
                </span>
                <TimeEditor
                  minutes={minutes}
                  seconds={seconds}
                  setMinutes={setMinutes}
                  setSeconds={setSeconds}
                  millies={millies}
                  setMillies={setMillies}
                />
              </div>
            </div>
          </div>
          <div className="height-17"></div>
          <div className="pending-request-image">
            <Image
              onClick={(e) => {
                if (document.fullscreenElement) {
                    document.exitFullscreen();
                  }
                e.currentTarget.requestFullscreen();
              }}
              className="w-100 h-100"
              style={{objectFit: "contain"}}
              src={submissionData.scoreScreenshot}
            />
          </div>
          <div className="height-17"></div>
          <div className="d-flex justify-content-between align-items-center">
            <DenyBtn
              initState={submissionData.status === "denied"}
              isAccepted={submissionData.status === "accepted"}
              handleSubmit={() => updateSubmissionOnClick("denied")}
            />
            <AcceptBtn
              initState={submissionData.status === "accepted"}
              isDenied={submissionData.status === "denied"}
              handleSubmit={() => updateSubmissionOnClick("accepted")}
            />
          </div>
        </>
      )}
    </div>
  );
}
