import { functions } from "services/firebaseConfig";


export const getAllUserSubmissionsBasedOnStatus = async (status: string, setData: React.Dispatch<any>, setError: React.Dispatch<any>) => {
    try{
        const getData = functions("getUserSubmissionDataBasedOnStatus");
        const userSubmission = await getData({status});
        setData(userSubmission.data);
    } catch (err) {        
            setError(err)
    }
}
