import React from "react"
import { AcceptedPlayerRequestCard } from "component/cards/AcceptedPlayerRequestCard";
import { Loading } from "component/loader/Loading";
import { useUserSubmission } from "hooks/useUserSubmission";
import { AdminDashboard } from "layout/AdminDashboardLayout";
import { activeNavBarButton, searchField } from "store/store";
import { filterRequests } from "helperFunctions/getDataFiltered";

export function DeniedDashboard(){
    activeNavBarButton.value = "DENIED";
    const { data, error, loading, setIsDataChanged, setLoading } = useUserSubmission("DENIED");
  let requestsData = filterRequests(searchField.value, data);
  if(error){
      return(
          <div>
              {error.message}
          </div>
      )
  }
    return(
        <AdminDashboard>
                {
                loading ? <Loading /> :
                requestsData && requestsData.map((eachRequest: any) => {
                return(
                    <React.Fragment key={`${eachRequest.submittedBy}_${eachRequest.challengeUid}_${eachRequest.game}`}>
                        <AcceptedPlayerRequestCard requestData={eachRequest} setIsDataChanged={setIsDataChanged} setLoading={setLoading}  />
                        <div className="height-17"></div>
                    </React.Fragment>
                )
            })
            }
        </AdminDashboard>
    )
}