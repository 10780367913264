import { NavButtonGroup } from "component/navButtonGroup/NavButtonGroup";
import { SearchBarAndQR } from "component/searchbar/SearchBar";
import { TitleHeader } from "component/titleHeader/TitleHeader";

export function AdminDashboard ({ children }: { children: any }){
    
    return(
        <div className="page-layout">
            <TitleHeader title="Admin Dashboard" />
            <div className="height-17"></div>
            <NavButtonGroup />
            <div className="height-17"></div>
            <SearchBarAndQR />
            <div className="height-17"></div>
            {children}
        </div>
    )
}