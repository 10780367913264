import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";

export function QRrecorder() {
  const navigate = useNavigate();
  const handleResult = (result: any, error: any) => {
    // on result
    if (result) {
      navigate(`/user/${result}`);
    }
    console.log(error);
    
  };
  return (
    <>
      <QrReader
        constraints={{ facingMode: "environment" }}
        onResult={(result, error) => handleResult(result, error)}
      />
    </>
  );
}
