import React from 'react'
import { themeColors } from "assets/themeColors";
import { activeNavBarButton, NavButtons } from "store/store";
import { useNavigate } from 'react-router-dom';


export function NavBtn ({title}:{title: NavButtons}){
    const navigate = useNavigate()
    
    const handleActive = () => {
        activeNavBarButton.value = title;
        
        navigate(`/dashboard/${title.toLowerCase()}`);
    }
    return(
        <button
        onClick={() => handleActive()}
        style={{ color: themeColors.font, background: activeNavBarButton.value === title ? themeColors.lenovo : themeColors.fg}}
        className="noselect nav-btn d-flex justify-content-center align-items-center"
        >
            {title.toUpperCase()}
        </button>
    )
}    