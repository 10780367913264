import { firestore } from "services/firebaseConfig";
import firebase from "firebase";
import { Challenge } from "models/challenges/challenge.model";
import { ChallengeDH } from "models/dreamhack/ChallengesDH.model";
import { JunctionProfileChallenge } from "models/challenges/junctionProfileChallenge.model";
import { ChallengeAdditionalData } from "models/challenges/challengeAdditionalData.model";
import { UsersFollowers } from "models/profiles/usersFollowers.model";
import { Profile } from "models/profiles/profile.model";
import { Post } from "models/posts/post.model";
import { Like } from "models/posts/postLike.model";
import { JunctionProfileChallengeDH } from "models/dreamhack/JunctionProfileChallengeDH";
/*
returns the firebase collection reference with type.
*/
const collectionRefWithType = <Type>(collectionName: string) =>
  firestore.collection(
    collectionName
  ) as firebase.firestore.CollectionReference<Type>;

// for comments/likes as subcollection for posts.
const subCollectionRefWithType = <T>(
  postId: string,
  subCollectionName: "comments" | "likes"
) =>
  collectionRefWithType<Post>("posts")
    .doc(postId)
    .collection(subCollectionName) as firebase.firestore.CollectionReference<T>;

export const collectionRef = {
  challenge: collectionRefWithType<Challenge>("challenges"),
  challengeDH: collectionRefWithType<ChallengeDH>("dreamhack_challenges"),
  junctionProfileChallenge: collectionRefWithType<JunctionProfileChallenge>(
    "junction_profile_challenge"
  ),
  junctionProfileChallengeDH: collectionRefWithType<JunctionProfileChallengeDH>(
    "dreamhack_junction_profile_challenge"
  ),
  posts: collectionRefWithType<Post>("posts"),
  userFollowers: collectionRefWithType<UsersFollowers>("usersFollowers"),
  profiles: collectionRefWithType<Profile>("profiles"),
  challengeFieldsToCompare: collectionRefWithType<ChallengeAdditionalData>(
    "challengesFieldsToCompare"
  ),
  comments: (postId: string) =>
    subCollectionRefWithType<Comment>(postId, "comments"),
  likes: (postId: string) => subCollectionRefWithType<Like>(postId, "likes"),
};
// ---------------------------------------------------------------------
// For fetching single document

const collectionDocData = <Type>(
  docId: string,
  docRef: firebase.firestore.CollectionReference<Type>
) => {
  return docRef
    .doc(docId)
    .get()
    .then((sc) => sc.data() as Type);
};

const collectionDocSnapshot = <Type>(
  docId: string,
  docRef: firebase.firestore.CollectionReference<Type>
) => {
  return docRef.doc(docId).get();
};

const commentDocSnapshot = (postId: string, docId: string) => {
  return collectionRef.comments(postId).doc(docId).get();
};

export const collectionDoc = {
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId challenge id
   * @returns Promise<FirebaseFirestore.DocumentSnapshot<Challenge>>
   */
  challenge: (docId: string) =>
    collectionDocSnapshot<Challenge>(docId, collectionRef.challenge),

  challengeDH: (docId: string) =>
    collectionDocSnapshot<ChallengeDH>(docId, collectionRef.challengeDH),

  challengeDHData: (docId: string) =>
    collectionDocData<ChallengeDH>(docId, collectionRef.challengeDH),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId junction profile challenge id
   * @returns Promise<FirebaseFirestore.DocumentSnapshot<JunctionProfileChallenge>>
   */
  junctionProfileChallenge: (docId: string) =>
    collectionDocSnapshot<JunctionProfileChallenge>(
      docId,
      collectionRef.junctionProfileChallenge
    ),

  junctionProfileChallengeDH: (docId: string) =>
    collectionDocSnapshot<JunctionProfileChallengeDH>(
      docId,
      collectionRef.junctionProfileChallengeDH
    ),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId post id
   * @returns Promise<FirebaseFirestore.DocumentSnapshot<Post>>
   */
  posts: (docId: string) =>
    collectionDocSnapshot<Post>(docId, collectionRef.posts),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId user follower id
   * @returns Promise<FirebaseFirestore.DocumentSnapshot<UsersFollowers>>
   */
  userFollowers: (docId: string) =>
    collectionDocSnapshot<UsersFollowers>(docId, collectionRef.userFollowers),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId challenge field to compare id
   * @returns Promise<FirebaseFirestore.DocumentSnapshot<ChallengeAdditionalData>>
   */
  challengeFieldsToCompare: (docId: string) =>
    collectionDocSnapshot<ChallengeAdditionalData>(
      docId,
      collectionRef.challengeFieldsToCompare
    ),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId profile id
   * @returns Promise<FirebaseFirestore.DocumentSnapshot<Profiles>>
   */
  profiles: (docId: string) =>
    collectionDocSnapshot<Profile>(docId, collectionRef.profiles),

  profileData: (docId: string) =>
    collectionDocData<Profile>(docId, collectionRef.profiles),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId comment id
   * @returns Promise<FirebaseFirestore.DocumentSnapshot<Comment>>
   */
  comments: (postId: string, docId: string) =>
    commentDocSnapshot(postId, docId),
};
// ---------------------------------------------------------------------
// For fetching multiple docs when array of doc ids is provided

const getAllCollectionDocs = async <Type>(
  docIds: string[],
  collectionName: string
) => {
  const collectionRefOfAllIds: Promise<
    firebase.firestore.DocumentSnapshot<Type>
  >[] = [];
  for (const docId of docIds) {
    const doc = collectionRefWithType<Type>(collectionName).doc(docId).get();
    collectionRefOfAllIds.push(doc);
  }
  return await Promise.all(collectionRefOfAllIds);
};

const getAllCommentsBasedOnCommentIds = async (
  postId: string,
  docIds: string
) => {
  const collectionRefOfAllIds: Promise<
    firebase.firestore.DocumentSnapshot<Comment>
  >[] = [];
  for (const docId of docIds) {
    const doc = collectionDoc.comments(postId, docId);
    collectionRefOfAllIds.push(doc);
  }
  return await Promise.all(collectionRefOfAllIds);
};

export const getAllCollectionDocsBasedOfDocIds = {
  challenge: (docIds: string[]) =>
    getAllCollectionDocs<Challenge>(docIds, "challenges"),
  junctionProfileChallenge: (docIds: string[]) =>
    getAllCollectionDocs<JunctionProfileChallenge>(
      docIds,
      "junction_profile_challenge"
    ),
  posts: (docIds: string[]) => getAllCollectionDocs<Post>(docIds, "posts"),
  userFollowers: (docIds: string[]) =>
    getAllCollectionDocs<UsersFollowers>(docIds, "usersFollowers"),
  profiles: (docIds: string[]) =>
    getAllCollectionDocs<Profile>(docIds, "profiles"),
  comments: getAllCommentsBasedOnCommentIds,
};

