import { signal } from "@preact/signals-react";

export type CurrentActiveAdmin = {
    name: string | null;
    uid: string;
    email: string;
}

export type NavButtons = "PENDING" | "ACCEPTED" | "DENIED"
export const activeNavBarButton = signal<NavButtons>("PENDING");
export const currentActiveAdmin = signal<CurrentActiveAdmin | undefined>(undefined);
export const isAuthenticated = signal<boolean | undefined>(undefined);

export const searchField = signal<string>("");