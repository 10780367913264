import firebase  from "firebase"

export const timestampToHrsNMinString = (time: any) => {
    const timeOfLog = firebase.firestore.Timestamp.fromMillis(time._seconds * 1000)
    const t = timeOfLog.toDate();
    return `${t.getHours()}:${t.getMinutes()}`
}

export const timestampToMins = (miliseconds: number) => {
    const t = firebase.firestore.Timestamp.fromMillis(miliseconds)
    return t.toDate();
}

export const timestampToHrsMinsAndDate = (time: any) => {
    const timeOfLog = firebase.firestore.Timestamp.fromMillis(time._seconds * 1000)
    const t = timeOfLog.toDate();
    
    return `${t.getHours()}:${t.getMinutes()}, ${t.getDate()}/${t.getMonth()}`
}