import { Image } from "react-bootstrap";
import { themeColors } from "assets/themeColors";
export function UserProfileDisk ({name, image, lg = false}: {name: string, image: string | undefined, lg: boolean}) {

    return(
        <div 
        style={{background: themeColors.lenovo, color: themeColors.font, fontSize: "20px"}}
        className={`d-flex justify-content-center align-items-center ${lg ? "profile-disk-lg" : "profile-disk"}`}>
            {image ? 
            <Image
            className={lg ? "profile-disk-lg" : "profile-disk"}
            fluid src={image} />    
                :
            <span 
            className="fw-semibold">{name && name.charAt(0).toUpperCase()}</span>
        }
        </div> 
    )
}