import { useEffect, useState } from "react"
import { getAllUserSubmissionsBasedOnStatus } from "services/submissions/getUserSubmissionsBasedOnStatus";


export const useUserSubmission = (status: string) => {
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [data, setData] = useState<any>(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(undefined);
    
    useEffect(() => {
        (function fetchData(){
            getAllUserSubmissionsBasedOnStatus(status.toLowerCase(), setData, setError)
        })();
        
        return () => {  
            setData(undefined)
        };
    }, [status, isDataChanged])
    
    useEffect(() => {
        if(data || error){
            setLoading(false);
        }
    
    }, [data, error])

    return {data, error, loading, setIsDataChanged, setLoading}
}