import { functions } from "services/firebaseConfig"


export const adminClaimGamepassTokenForUserDH = async (profileId: string, setData: React.Dispatch<React.SetStateAction<any>>) => {
    try{
        const getData = functions("adminClaimGamepassTokenForUserDH")
        const response = (await getData({profileId: profileId})).data;
        setData(response);
    } catch (error) {
        setData(error)
    }
}