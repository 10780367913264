import { Gamepass, Legion, Intel } from "assets/logos/Logos";

export function LandingPageHeader(){
    return(
        <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex flex-column align-items-start gap-3">
                <Legion />
                <Intel />
                <Gamepass />
            </div>
            <span className="text-18 fw-bold theme-font-light">Admin dashboard</span>
        </div>
    )
}