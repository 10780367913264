import { collectionRef } from "collectionRefs/collectionOperations";
import { FieldValue, firestore } from "services/firebaseConfig";


const raffleCollectionRef = firestore.collection("raffle_winners");
export async function getAllUsers(setUsers: any, setError: any, setLoading: any){

    try{
        const userParticipatingInRaffle = await collectionRef.profiles.where("participatesInRaffle", "==", true).get();
        const allUserProfiles = userParticipatingInRaffle.docs.map((profileDoc) => profileDoc.data() as any);
        setUsers(allUserProfiles);
        setLoading(false);
    } catch (err: any) {
        setLoading(false);
        setError(err);
    }
}

export const getRaffleWinners = async (setWinners: any, setError: any) => {
    try{
        const raffleWinnersDocs = await raffleCollectionRef.get();
        const raffleWinnersData = raffleWinnersDocs.docs.map((winners) => winners.data());
        setWinners(raffleWinnersData);
    } catch (error) {
        setError(error)
    }
}

export const deleteWinner = async (uid: string) => {
    await raffleCollectionRef.doc(uid).delete();
}
export const setRaffleWinner = async (uid: string, adminUid: string, day: string, winnerNumber: number, userName: string, prize: string) => {
    try{    
        await raffleCollectionRef.doc(uid).set({
                uid,
                created: FieldValue.serverTimestamp(),
                won: true,
                adminUid,
                day,
                winnerNumber,
                prize,
                userName: userName
        })
    } catch (err) {
        console.log(err)
    }
}