import { useEffect, useState } from 'react'

export function OutlinedToFilledButton({ title, title2, handleClick, initState }: { title: any, title2: any, handleClick: Function, initState: boolean }){
    const [isFilled, setIsFilled] = useState(initState);

    useEffect(() => { setIsFilled(initState) }, [initState])
    const btnClick = () => {
        setIsFilled(true);
        handleClick();
    }
    return(
        <span 
        onClick={() => btnClick()}
        className={`noselect d-flex justify-content-center align-items-center
          ${isFilled ? "filled-btn" : "outlined-btn"}`}
        >
            {isFilled ? title2 : title }
        </span>
    )
}