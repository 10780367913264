import  firebase from "firebase/app"
import "firebase/functions"
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyBdeZ5Tr5J8Xkq_1tTXQSZX0GUKMFvk7KI",
    authDomain: "world-of-gamers-d663f.firebaseapp.com",
    databaseURL: "https://world-of-gamers-d663f-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "world-of-gamers-d663f",
    storageBucket: "world-of-gamers-d663f.appspot.com",
    messagingSenderId: "731640606038",
    appId: "1:731640606038:web:f3464c57793d30166fbaf7",
    measurementId: "G-58QC60YGQY"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firestore = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage();

//Classes and Types
export const FieldValue = firebase.firestore.FieldValue; 
export const timestamp = firebase.firestore.Timestamp.now();
export type Timestamp = typeof timestamp;
export type QuerySnapshot = firebase.firestore.QuerySnapshot

export const timestamp2 = firebase.firestore.Timestamp;
export type Timestamp2 = typeof timestamp;

//functions
export const functions = (functionName: string) =>  firebase.app().functions("europe-west1").httpsCallable(functionName);
