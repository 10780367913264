
export function FilledBtn({ title, handleClick }: { title: any, handleClick: Function }){

    return(
        <div 
        onClick={() => handleClick()}
        className="noselect d-flex justify-content-center align-items-center filled-btn">
            { title }
        </div>
    )
}