import { Image } from "react-bootstrap";

export function ChallengeImage ({ image}: { image: string}){
    return(
        <div className="square-77">
            <Image
             onClick={() => {
                window.location.href = `${image}`; 
              }}
            src={image} className="square-77" />
        </div>
    )
}