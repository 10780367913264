
export function TitleHeader ({title}: {title: string}){
    
    return (
        <span
        className="text-28 theme-font-light">
            {title}
        </span>
    )
}
