import { themeColors } from "assets/themeColors";
import { Button } from "react-bootstrap";

export function ReedemButton({ title, handleClick, disabled }: { title: any, handleClick: Function, disabled: boolean }){

    return(
        <Button
        disabled={disabled}
        onClick={() => handleClick()}
        style={{background: themeColors.lenovo, color: themeColors.font, border: "none"}}
        className="noselect d-flex justify-content-center align-items-center accept-btn">
            {disabled ? "REEDEMED" : title }
        </Button>
    )
}