import { Logs } from "models/logs/Logs";
import { FieldValue, firestore } from "services/firebaseConfig";


export const updateSubmissionStatus = async (submissionId: string, status: string, log: Logs[] | [], newScoreValue: number | undefined, setError: React.Dispatch<any>) => {
   try{
       await firestore.collection("dreamhack_submissions").doc(submissionId).update({
           status: status.toLowerCase(),
           logs: FieldValue.arrayUnion(...log),
           scoreValue: newScoreValue ? newScoreValue : FieldValue.increment(0)
        })
   } catch (err: any){
      if(err.message === "Missing or insufficient permissions."){
         setError("Failed to change finished challenges status!")
      }
   }
}