
import { FilledBtn } from "component/buttons/Fillterbtn";
import { ReedemButton } from "component/buttons/ReedemButton";
import { UserProfileDisk } from "component/cards/components/UserProfileDisk";
import { UserChallengeCard } from "component/cards/UserChallengeCard";
import { Loading } from "component/loader/Loading";
import { ActionLog } from "component/logs/ActionLogs";
// import { SearchBarAndQR } from "component/searchbar/SearchBar";
import { TitleHeader } from "component/titleHeader/TitleHeader";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { adminClaimGamepassTokenForUserDH } from "services/cloudfunctions/adminClaimGamepassTokenForUserDH";
import { getUserRequestsWhenScanWithQR } from "services/cloudfunctions/getUserRequestsWhenScanWithQR";
import { isTokenAlreadyClaimed } from "services/token/isTokenAlreadyClaimed";
import { TypegetUserRequestsWhenScanWithQR } from "types/cloudfunctionResponse/TypegetUserRequestsWhenScanWithQR";
import {Gamepass} from "../assets/logos/Logos"

export function UserProfile(){
    const { uid } = useParams();

    const [loading, setIsLoading] = useState(true); // we will keep it true untill we get all docs.
    const [data, setData] = useState<TypegetUserRequestsWhenScanWithQR | undefined>(undefined);
    const [error, setError] = useState<any>(undefined);
    const [tokenData, setTokenData] = useState<any>();
    const [loadingTokenClaim, setLoadingTokenClaim] = useState(false);
    const [isReedemed, setIsReedemed] = useState(true);

    const handleTokenRequest = () => {
        setIsReedemed(true);
        adminClaimGamepassTokenForUserDH(uid!, setTokenData);
    }

    
    useEffect(() => {
        if(uid){
            getUserRequestsWhenScanWithQR(uid, setData, setError);
            isTokenAlreadyClaimed(uid, setIsReedemed, setLoadingTokenClaim )
        }
    }, [uid])

    // when data/error is available, set loading false
    useEffect(() => {
        if(data || error){
            setIsLoading(false);
        }
        
    }, [data, error])

    
    if(error){
        return(
            <div className="text-18 text-center theme-font-light action-log-container">
                {error.message}
            </div>
        )
    }

    if(loading){
        return <Loading />
    }

    if(!data){
        return (
            <div className="text-18 text-center theme-font-light action-log-container">
                User's Data not found!
            </div>
        )
    }

    const { profile, actionLog, requests } = data;

    return<>
    <TitleHeader title={`Admin dashboard / ${profile.slug }`} />
    <div className="height-15"></div> 
    <div className="height-15"></div>
    <div className="user-info-container d-flex gap-3 align-items-center">
        <UserProfileDisk name={profile.userName!} image={profile.profileLogoUrl} lg={true}  />
        <div className="d-flex flex-column gap-1 align-items-start theme-font-light ">
            <span className="fw-bold text-24">{profile.slug}</span>
            <span className="text-16">{profile.fullName}</span>
        </div>
        <div className="flex-fill d-flex flex-column align-items-end gap-3">
            <Gamepass />
            {
            loadingTokenClaim ? <Loading /> :
          <ReedemButton handleClick={() => handleTokenRequest()} title="REDEEM CODE" disabled={isReedemed} />
            }
        </div>
    </div>
    {tokenData && tokenData.message === "Token already claimed!" && 
        <div className="text-18 text-center theme-font-light action-log-container mt-2 mb-2">
            <span>{tokenData.message}</span>
        </div>
    }
    {renderRequests(requests)}
    <div className="height-30"></div> 
    <TitleHeader title="Action log" />
    {renderActionLogs(actionLog)}
    </>
}

function renderActionLogs(logs: any){
    return logs.map((log: any) => {
        return(
            <React.Fragment key={log.logId}>
                <ActionLog adminName={log.adminName} challengeName={log.challengeName} decision="Approved" date={log.created} />
            </React.Fragment>
        )
    })
}

function renderRequests(requests: any) { 
   return requests.map((req: any) => {
        const challengeData = {
            name: req.name,
            logoUrl: req.logoUrl,
            game: req.game,
            endDate: req.endDate,
            isAccepted: req.isAccepted,
            junctionProfileChallengeId: req.junctionProfileChallengeId,
        }
        return( <React.Fragment key={req.junctionProfileChallengeId}>
            <div className="height-15"></div>
            <UserChallengeCard challenge={challengeData} />
        </React.Fragment>)
    })
  }