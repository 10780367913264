import { Header } from "component/header/Header";
import { LandingPageHeader } from "component/header/LandingPageHeader";
import { Loading } from "component/loader/Loading";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { auth } from "services/firebaseConfig";

import { currentActiveAdmin, isAuthenticated } from "../store/store";
import { LandingPage } from "./LandingPage";
export const ProtectedRoutesFN = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Check if user is authenticated.
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {      
      if (user) {
        currentActiveAdmin.value = {
          name: user.displayName,
          email: user.email!,
          uid: user.uid
        }
        isAuthenticated.value = true;
      } else {
        isAuthenticated.value = false;
      }
    });
  }, []);

  // if user is authenticated, the isAuth will change and trigger this effect
  useEffect(() => {
    if (isAuthenticated.value !== undefined) {
      setIsLoading(false); //This will trigger another render
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated.value]);
  
  //this will be a fall back page unless isLoading is false.
  if (isLoading) {
    return (
      <>
       <LandingPageHeader />
       <Loading />
      </>
    );
  }
  return (
    <>{isAuthenticated.value ?
        <>  
          <Header />
          <div className="height-80"></div>
          <Outlet /> 
        </> 
    : <LandingPage />}</>
  ); //return Outlet (i.e. dashboard page) if authorized, otherwise landingPage
};
export const ProtectedRoutes = React.memo(ProtectedRoutesFN)