import { FilledBtn } from "component/buttons/Fillterbtn";
import { OutlinedBtn } from "component/buttons/OutlinedBtn";
import { Modal, ModalBody } from "react-bootstrap";


export function DenyConfirmationModal({ show, setShow, handleClick }: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, handleClick: Function }){
    const handleClose = () => { 
        setShow(false)
    }
    return(
        <Modal 
        centered
        show={show} onHide={() => handleClose()}>
        <ModalBody className="deny-modal">

        <div className="fw-bold text-22 theme-font-light text-center mb-5">You sure you want to deny this request?</div> 
        <div className="d-flex justify-content-between">
        <OutlinedBtn title="CANCEL" handleClick={() => handleClose()} />
        <FilledBtn title="DENY" handleClick={() => handleClick()} />
        </div>
        </ModalBody>
      </Modal>
    )
}