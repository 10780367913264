import React, { useState } from "react"
import { themeColors } from "assets/themeColors"
import { AcceptBtn } from "component/buttons/Accept"
import { DenyBtn } from "component/buttons/Deny"
import { UserProfileDisk } from "component/cards/components/UserProfileDisk"
import { timestampToHrsNMinString } from "helperFunctions/timestampToDate"
import { UserSummitionRequest } from "types/cloudfunctionResponse/TypeUserSubmissionRequests"
import { ChallengeImage } from "./components/ChallengeImage"
import { ActionLog } from "component/logs/ActionLogs"
import { useUserSubmissions } from "component/submissionFunction/UserSubmissionsFunction"
import { TimeEditor } from "./components/TimeEditor"

export function AcceptedPlayerRequestCard(
    { 
        requestData,
        setIsDataChanged,
        setLoading
    }: 
    { 
        requestData: UserSummitionRequest,
        setIsDataChanged: any;
        setLoading: any;
    })
    {
    const {finishedChallengeError, profile, submissionData, time, updateSubmissionOnClick} = useUserSubmissions(requestData, setIsDataChanged, setLoading);
    const { minutes, seconds, millies, setMinutes, setSeconds, setMillies } = time;
    const [showLogs, setShowLogs] = useState(false);
    const { logs } = submissionData;
    const styles = {
        background: themeColors.fg
    }
    
    return (
        <>
        <div 
        style={styles}
        className="request-card d-flex flex-column">
            {
            finishedChallengeError ? (
                <span className="fw-bold text-24 text-center theme-font-light">
                    {finishedChallengeError}
                </span>
                ) :
            <>
            <div className="d-flex align-items-start justify-content-between">
                <div className="d-flex align-items-start justify-content-between">
                    <UserProfileDisk name={profile.slug} image={profile.profileLogoUrl} lg={false} />
                    <div style={{width: "16px"}}></div>
                    <div className="d-flex flex-column">
                        <span 
                        style={{color: themeColors.font}}
                        className="text-20">{profile.slug}</span>
                        <span
                        style={{color: themeColors.fontSupplemental}}
                        className="text-18">{submissionData.game} / {submissionData.name}
                        </span>
                        <span
                        style={{color: themeColors.fontSupplemental}}
                        className="text-16">Submitted at {timestampToHrsNMinString(submissionData.submittedAt)}
                        </span>
                    </div>
                </div>
                <div className="d-flex">
                    <ChallengeImage image={submissionData.scoreScreenshot} />
                    <div style={{width: "20px"}}></div>
                    <div className="d-flex flex-column align-items-start justify-content-between">
                        <span style={{ color: themeColors.font }} className="text-18">
                          Value({submissionData.scoreValueType})
                        </span>
                        <TimeEditor
                          minutes={minutes}
                          seconds={seconds}
                          setMinutes={setMinutes}
                          setSeconds={setSeconds}
                          millies={millies}
                          setMillies={setMillies}
                        />
                    </div>
                </div>
            </div>
            <div style={{height: "17px"}}></div>
            <div className="d-flex justify-content-between align-items-center">
                <DenyBtn
                initState={submissionData.status === "denied"}
                isAccepted={submissionData.status === "accepted"}
                handleSubmit={() => updateSubmissionOnClick("denied")}
                />
                <span 
                style={{color: themeColors.font}}
                className="text-16"
                onClick={() => setShowLogs(prevState => !prevState)}
                >Action Log</span>
                <AcceptBtn
                initState={submissionData.status === "accepted"}
                isDenied={submissionData.status === "denied"}
                handleSubmit={() => updateSubmissionOnClick("accepted")}
                />
            </div>
            </>
            }
        </div>
        {showLogs && logs && logs.map((log: any, index: any) => {
            return( <React.Fragment key={`${log.uid}_${log.time}_${log.adminName}_${index}`}>
                <ActionLog adminName={log.adminName} challengeName={undefined} date={log.time} decision={log.action} />
            </React.Fragment>)
        })}
        </>
    )
}
