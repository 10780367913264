import { Loading } from "component/loader/Loading";
import { TitleHeader } from "component/titleHeader/TitleHeader";
import { useState, useEffect } from "react";
import { getAllUsers, getRaffleWinners } from "services/raffle/getRandomUser";
import { getAllDreamhackusers, tokenReedemed } from "services/stats/getAllDreamhackUser";

export function Stats(){
    const [totalParticipants, setTotalParticipants] = useState<any>();
    const [totalRaffleParticipants, setTotalRaffleParticipants] = useState<any>();
    const [raffleWinners, setRaffleWinners] = useState<any[]>([]);
    const [tokenNumbers, setTokenNumbers] = useState<any[]>([]);
    const [error, setError] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);

    console.log(raffleWinners);
    
    const setLoading = (loadingStat: boolean) => null;
    useEffect(() => {
       (async function fetchData(){
           await getRaffleWinners(setRaffleWinners, setError)
           await getAllUsers(setTotalRaffleParticipants, setError, setLoading)
           await getAllDreamhackusers(setTotalParticipants, setError, setLoading)
           await tokenReedemed(setTokenNumbers, setError);
           setIsLoading(false);
        })()
    }, [])

    if(isLoading){
        return <>
             <TitleHeader title="Stats" />
             <br />
             <Loading />
        </>
    }
    
    if(error){
        return <>
             <TitleHeader title="Stats" />
             <div className="raffle-title text-center">
            <h5>Error: {error.message}</h5>
        </div>
        </>
    }

    return(
        <div className="width-734">
        <TitleHeader title="Stats" />
        <br />
        <div className="raffle-title text-center">
            <h5>Total dreamhack Participants: {totalParticipants && totalParticipants.length}</h5>
        </div>
        <div className="raffle-title text-center">
            <h5>Total Raffle Participants: {totalRaffleParticipants && totalRaffleParticipants.length}</h5>
        </div>
        <div className="raffle-title text-center">
            <h5>Total Token Reedemed: {tokenNumbers && tokenNumbers.length}</h5>
        </div>
        <div className="raffle-title text-center">
            <h5>Raffle Winners</h5>
            {raffleWinners.length === 0 ? <> No Winners yet! </> :  raffleWinners.map((winners: any, ind: number) => {
                return <span>
                    <h6>{ind + 1}. {winners.userName} won {winners.prize} on {winners.day.split("-")[0]}</h6>
                </span>
            })}
        </div>
        </div>
    )
}