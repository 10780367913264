import { Spinner } from "react-bootstrap";

export function Loading(){
    return <>
    <Spinner
    className="spinnerrr"
    style={{borderRadius: "50% !important"}}
    animation="border" variant="light"
     />
    </>
}