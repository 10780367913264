import { PendingPlayerRequestCard } from "component/cards/PendingPlayerRequestCard";
import { Loading } from "component/loader/Loading";
import { filterRequests } from "helperFunctions/getDataFiltered";
import { useUserSubmission } from "hooks/useUserSubmission";
import { AdminDashboard } from "layout/AdminDashboardLayout";
import React from "react";
import { activeNavBarButton } from "store/store";
import { searchField } from "store/store";

export function PendingDashboard(){
    activeNavBarButton.value = "PENDING";
    
    const { data, error, loading, setIsDataChanged, setLoading } = useUserSubmission("PENDING");
    let requestsData = filterRequests(searchField.value, data);
    if(error){
        return(
            <div>
                {error.message}
            </div>
        )
    }

    return(
        <AdminDashboard>
            {
            loading ? <Loading /> :
            requestsData && requestsData.map((eachRequest: any, index: number) => {
                return(
                    <React.Fragment key={`${eachRequest.submissionData.submittedId}_${index}`}>
                          <PendingPlayerRequestCard requestData={eachRequest} setIsDataChanged={setIsDataChanged} setLoading={setLoading}/>
                          <div className="height-17"></div>

                    </React.Fragment>
                )
            })
            }
        </AdminDashboard>
    )
}