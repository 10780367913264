import { FilledBtn } from "component/buttons/Fillterbtn"
import { LoginLayout } from "layout/LoginLayout"
import { useNavigate } from "react-router-dom"
export function LandingPage(){
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate("/login")
    }

    return(
        <LoginLayout>
            <span
            className="fw-bold text-48 theme-font-light "
            >Stylish outside. Savage inside.</span>
            <span className="w-50 text-center theme-font-light  fw-normal text-20 mb-4">Pellentesque habitant morbi tristique senectus et netus.</span>
            <FilledBtn title="LOG IN" handleClick={() => handleLogin()} />
        </LoginLayout>
    )
}

