import {
  Login,
  PendingDashboard,
  DeniedDashboard,
  AcceptedDashboard,
  ProtectedRoutes,
  Raffle,
  Stats,
} from "pages";
import { Error } from "pages/Error";
import { ScanQR } from "pages/Scan";
import { UserProfile } from "pages/UserProfile";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: <ProtectedRoutes />,
      errorElement: <Error />,
      children: [
        {
          path: "/",
          element: <PendingDashboard />,
        },
        {
          path: "/scan-user",
          element: <ScanQR />,
        },
        {
          path: "dashboard/accepted",
          element: <AcceptedDashboard />,
        },
        {
          path: "dashboard/pending",
          element: <PendingDashboard />,
        },
        {
          path: "dashboard/denied",
          element: <DeniedDashboard />,
        },
        {
          path: "user/:uid",
          element: <UserProfile />,
        },
        {
          path: "/daily-raffle",
          element: <Raffle />
        },
        {
          path: "/stats",
          element: <Stats />
        }
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}
