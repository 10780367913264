import React, { useState, useEffect } from "react";
import { TitleHeader } from "component/titleHeader/TitleHeader";
import { Profile } from "models/profiles/profile.model";
import { RaffleCard } from "component/cards/raffleCard";
import { getAllUsers, getRaffleWinners } from "services/raffle/getRandomUser";
import { Loading } from "component/loader/Loading";
import { auth } from "services/firebaseConfig";

export function Raffle(){
    const [allRaffleParticipants, setAllRaffleParticipants] = useState<Profile[]>([]);
    const [loading, setLoading] = useState(true);
    const [winnersProfile, setWinnersProfile] = useState<any>([]);
    const [error, setError] = useState<any>(undefined);

    useEffect(() => {
        getAllUsers(setAllRaffleParticipants, setError, setLoading);
        getRaffleWinners(setWinnersProfile, setError);
    }, [])

    const handleRaffleChoose = (setWinner: any) => {
        if(allRaffleParticipants){
            const random = Math.round(Math.random() * allRaffleParticipants.length);
            const winner = allRaffleParticipants[random]
            setWinner(winner);
        }
    }

    if(loading){ 
        return <>
            <TitleHeader title="Daily Raffle" />
            <Loading />
        </>
    }

    if(error){ 
        return <>
            <TitleHeader title="Daily Raffle" />
            <h4 className="raffle-title text-center">Error: {error.message}</h4>
        </>
    }


    return (
        <div>
        <TitleHeader title="Daily Raffle" />
        <div className="raffle-title text-center">
            <h5>Total Raffle Participants: {allRaffleParticipants.length}</h5>
        </div>
        <br />
        <br />
        <DailyRaffle day="Friday" handleRaffleChoose={handleRaffleChoose} winnersProfile={winnersProfile} />
        <br />  
        <br />  
        <DailyRaffle day="Saturday" handleRaffleChoose={handleRaffleChoose} winnersProfile={winnersProfile} />
        <br />  
        <br />  
        <DailyRaffle day="Sunday" handleRaffleChoose={handleRaffleChoose} winnersProfile={winnersProfile} />
        </div>
    )
}


const DailyRaffle = ({ day, handleRaffleChoose, winnersProfile }: { day: string, handleRaffleChoose: any, winnersProfile: any}) => {
    const [randomProfile1, setRandomProfile1] = useState<any>();
    const [randomProfile2, setRandomProfile2] = useState<any>();

    useEffect(() => {
        if(winnersProfile){
            const day1Winner = winnersProfile.find((winner: any) => winner.day === `${day}-1`)
            const day2Winner = winnersProfile.find((winner: any) => winner.day === `${day}-2`)

            setRandomProfile1(day1Winner);
            setRandomProfile2(day2Winner);
        }
    }, [winnersProfile])


 
    return  <>
    <div className="raffle-title text-center">
        <h5>{day} Winners</h5>
    </div>
    <RaffleCard 
    userName={randomProfile1 && randomProfile1.userName} 
    uid={randomProfile1 && randomProfile1.uid} 
    handleRaffleChoose={() => handleRaffleChoose(setRandomProfile1)} 
    adminUid={auth.currentUser?.uid!} 
    day={`${day}-1`} 
    winnerNumber={1}
    isAlreadyConfirmed={randomProfile1 && randomProfile1.won}
    prize="Motorola Edge 30 Neo"
    />
    <RaffleCard 
    userName={randomProfile2 && randomProfile2.userName} 
    uid={randomProfile2 && randomProfile2.uid} 
    handleRaffleChoose={() => handleRaffleChoose(setRandomProfile2)} 
    adminUid={auth.currentUser?.uid!} 
    day={`${day}-2`}  
    winnerNumber={2}
    isAlreadyConfirmed={randomProfile2 && randomProfile2.won === true}
    prize={`Lenovo 25" G25-20`}

    />
    </>
}