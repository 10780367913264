import { timestampToHrsMinsAndDate } from "helperFunctions/timestampToDate";

type Props = {
    adminName: string;
     decision: string;
     challengeName: string | undefined;
     date: any;
}
export function ActionLog({adminName, decision, challengeName, date}: Props){

    return(
        <div className="action-log-container d-flex align-items-center justify-content-between theme-font-light mt-1">
            <span className="text-14 width-33"> { adminName }</span>
            <div className="d-flex align-items-center gap-4 flex-fill ">
                <span className="text-14"> { capitalizeFirstLetter(decision) }</span>
                {challengeName && <span className="text-14"> { challengeName }</span>}
            </div>
            <span className="text-14 flex-fill text-end"> {timestampToHrsMinsAndDate(date)}</span>
        </div>
    )
}

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() +  str.slice(1);
  }
  