import { collectionRef } from "collectionRefs/collectionOperations";
import { firestore } from "services/firebaseConfig";


export async function getAllDreamhackusers(setUsers: any, setError: any, setLoading: any){

    try{
        const userParticipatingInRaffle = await collectionRef.profiles.where("dreamHackUser", "==", true).get();
        const allUserProfiles = userParticipatingInRaffle.docs.map((profileDoc) => profileDoc.data() as any);
        setUsers(allUserProfiles);
        setLoading(false);
    } catch (err: any) {
        setLoading(false);
        setError(err);
    }
}

export const tokenReedemed = async (setUsers: any, setError: any) => {
    try{
        const allTokenReedemed = await firestore.collection("dreamhack_gamepass_tokens").where("isTaken", "==", true).get();
        const allUserProfiles = allTokenReedemed.docs.map((token: any) => token.data());
        setUsers(allUserProfiles);
    } catch (err: any) {
        setError(err);
    }
}