import { themeColors } from "assets/themeColors"
import { useState } from "react"
import { Button } from "react-bootstrap";

export function AcceptBtn ({ initState, isDenied, handleSubmit }: { initState: boolean, isDenied: boolean, handleSubmit: Function }){
    const [isAccepted, setIsAccepted] = useState(initState);
    return(
        <Button 
        disabled={isAccepted}
        onClick={() => { 
                setIsAccepted(true)
                handleSubmit()
        }}
        style={{background: themeColors.lenovo, color: themeColors.font, border: "none"}}
        className="noselect d-flex justify-content-center align-items-center accept-btn">
            {isAccepted ? <span className="d-flex flex-row  justify-content-center align-items-center gap-2"><AcceptedSvg /> ACCEPTED </span> : "ACCEPT" }
        </Button>
    )
}    

const AcceptedSvg = () => <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.61526 14.656L0.215776 9.17387C0.058127 8.97541 -0.0177933 8.72425 0.00352644 8.47169C0.0248461 8.21913 0.141797 7.98425 0.330484 7.81503C0.422814 7.73431 0.530581 7.6732 0.647245 7.6354C0.763909 7.59759 0.887037 7.58389 1.00916 7.59511C1.13128 7.60633 1.24985 7.64225 1.35767 7.70068C1.46549 7.75911 1.56031 7.83884 1.63639 7.93503L6.03676 13.4163C6.36323 13.824 6.31294 14.4346 5.92117 14.7751C5.82878 14.8558 5.72097 14.9169 5.60427 14.9546C5.48757 14.9923 5.36443 15.006 5.2423 14.9946C5.12018 14.9833 5.00163 14.9473 4.89384 14.8888C4.78605 14.8303 4.69127 14.7505 4.61526 14.6543V14.656Z" fill="#F1F5FF"/>
<path d="M4.78332 14.7752C4.59372 14.6069 4.4756 14.3724 4.45313 14.1198C4.43066 13.8672 4.50554 13.6156 4.66244 13.4164L15.1238 0.345883C15.1995 0.249312 15.294 0.169134 15.4016 0.110219C15.5093 0.0513034 15.6277 0.0148803 15.7499 0.00316193C15.872 -0.00855648 15.9953 0.00467431 16.1121 0.0420507C16.229 0.0794271 16.337 0.140169 16.4297 0.220587C16.8215 0.560299 16.8762 1.1709 16.5506 1.57943L6.08835 14.6544C6.01266 14.7509 5.91813 14.8311 5.81051 14.89C5.70288 14.9489 5.5844 14.9854 5.46226 14.9971C5.34013 15.0088 5.21689 14.9956 5.10002 14.9582C4.98316 14.9208 4.87511 14.8601 4.78244 14.7797V14.7744L4.78332 14.7752Z" fill="#F1F5FF"/>
</svg>
 