import { useState } from "react";
import { updateSubmissionStatus } from "services/submissions/updateSubmissionStatus";
import { Logs } from "models/logs/Logs";
import { currentActiveAdmin } from "store/store";
import firebase from "firebase";
import {
  timestampToMins,
} from "helperFunctions/timestampToDate";

export function useUserSubmissions(requestData: any, setIsDataChanged: any, setLoading: any) {
  const { profile, submissionData } = requestData;
  

  // if time, then convert milliseconds to minutes, else leave it as points  
  const [minutes, setMinutes] = useState(
    submissionData.scoreValueType === "time" ?
    timestampToMins(submissionData.scoreValue).getMinutes()
    : 
    submissionData.scoreValue as number
  );

  // if its time type, then init it otherwise not
  const [seconds, setSeconds] = useState(
    submissionData.scoreValueType === "time" &&
    timestampToMins(submissionData.scoreValue).getSeconds()
  );
  const [millies, setMillies] = useState(
    submissionData.scoreValueType === "time" &&
    timestampToMins(submissionData.scoreValue).getMilliseconds()
  );

  const [finishedChallengeError, setFinishedChallengeError] =
    useState<any>(undefined);


  function handleSetLogs() {
    let logs: Logs | undefined = undefined;
    let newScoreValue: any = undefined;
    if (
      minutes !== timestampToMins(submissionData.scoreValue).getMinutes() ||
      seconds !== timestampToMins(submissionData.scoreValue).getSeconds() ||
      millies !== timestampToMins(submissionData.scoreValue).getMilliseconds()
    ) {
      // converting our collected data to milliseconds
      if(submissionData.scoreValueType === "time"){
        newScoreValue = minutes * 60000 + +seconds * 1000 + +millies;
      }else{
        newScoreValue = minutes;
      }
      logs = {
        action: "edited",
        adminName: currentActiveAdmin.value?.name!,
        time: firebase.firestore.Timestamp.now(),
        uid: currentActiveAdmin.value?.uid!,
      };
    }
    return { logs, newScoreValue };
  }

  async function updateSubmissionOnClick(status: "accepted" | "denied") {
    const allLogs: Logs[] = [];
    const { logs, newScoreValue } = handleSetLogs();
    if (logs) {
      allLogs.push(logs);
    }
    const currentLog: Logs = {
      uid: currentActiveAdmin.value?.uid!,
      action: status,
      adminName: currentActiveAdmin.value?.name!,
      time: firebase.firestore.Timestamp.now(),
    };
    allLogs.push(currentLog);
    await updateSubmissionStatus(
      submissionData.submissionId,
      status,
      allLogs,
      newScoreValue,
      setFinishedChallengeError
    );
    setIsDataChanged((prevState: boolean) => !prevState)
    setLoading(true);
  }
  const time = { minutes, seconds, millies, setMinutes, setSeconds, setMillies }
  return {finishedChallengeError, profile, submissionData, time, updateSubmissionOnClick}
}
