import { DenyConfirmationModal } from "component/Modals/DenyConfirmationModal";
import { useState } from "react"
import { Button } from "react-bootstrap";

export function DenyBtn ({ initState, isAccepted, handleSubmit }: { initState: boolean, isAccepted: boolean, handleSubmit: Function | undefined }){
    const [isDenied, setIsDenied] = useState(initState);
    const [show, setShow] = useState(false);
  
    const handleClick = () => {
        setIsDenied(true)
        setShow(false)
        if(handleSubmit) handleSubmit()
    }
    return(
        <>
        <Button
        disabled={isDenied}
        onClick={() => setShow(!show)}
        className="noselect d-flex justify-content-center align-items-center outlined-btn deny-btn">
            {isDenied ? "DENIED" : "DENY"}
        </Button>
        <DenyConfirmationModal show={show} setShow={setShow} handleClick={handleClick} />
            </>
    )
}    