import React from "react";
import "./assets/stylesheet/custom.css"
import "./assets/stylesheet/utils.css"
import ReactDOM from "react-dom";
import App from "./App";



ReactDOM.render(
  <>
      <App />
  </>,
  document.getElementById("root")
);