import { LandingPageHeader } from "component/header/LandingPageHeader";
import { Image } from "react-bootstrap";
import background from "../assets/background.png";

export function LoginLayout({ children }: { children: any }) {
  return (
    <>
      <LandingPageHeader />
      <div className="position-absolute w-100 h-100 landing-page-bg">
        <Image style={{ minWidth: "100vw" }} src={background} />
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center landing-page-dimensions">
        {children}
      </div>
    </>
  );
}
