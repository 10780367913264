import { useState } from "react"
export function PasswordField({passwordRef}:{passwordRef: React.MutableRefObject<string>}){
    const [show, setShow] = useState(false);

    const handleChange = (value: string) => {
        passwordRef.current = value
    }
   
    return(
        <div>
           <div className="d-flex flex-column align-items-start">
            <span className="fw-bold text-24 theme-font-light">Password</span>
            <div style={{height: "8px"}}></div>
            <div className="input-container">
                 <input
                 type={show ? "text" :"password"}
                 onChange={(e) => handleChange(e.target.value)}
                  />
                  <span
                  onClick={() => setShow(prevState => !prevState)}
                  className="text-muted text-15"> SHOW </span>
            </div>
        </div>
        </div>
    )
}