import { NavBtn } from "component/buttons/Nav";



export function NavButtonGroup(){

    return(
        <div className="d-flex">
            <NavBtn title="PENDING" />
            <span className="width-10"></span>
            <NavBtn title="ACCEPTED" />
            <span className="width-10"></span>
            <NavBtn title="DENIED" />
        </div>
    )
}