import { useState } from "react"
import { verifyEmailAddress } from "services/auth/loginUserWithEmailAndPasssword";

export function EmailField({  emailRef }:{emailRef: React.MutableRefObject<string>}){
    
    const [isEmailValid, setisEmailValid] = useState(undefined);
    const handleChange = (value: string) => {
        if(value === ""){
            setisEmailValid(undefined)
        }
        emailRef.current = value
    }
    const handleBlur = () => {
        verifyEmailAddress(emailRef.current, setisEmailValid)
    }
    return(
        <div className="d-flex flex-column align-items-start">
            <span className="fw-bold text-24 theme-font-light">Email</span>
            <div style={{height: "8px"}}></div>
            <div className="input-container">
                 <input
                 onChange={(e) => handleChange(e.target.value)}
                 onBlur={() => handleBlur()}
                 type="text"
                  />
                 {isEmailValid !== undefined && 
                 <> 
                    {isEmailValid ? 
                        <CheckmarkSVG />
                        : 
                        <CrossSvg/>
                    }
                 </>
                 
                 }
            </div>
        </div>
    )
}
const CheckmarkSVG = () => <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.11843 13.9796L1.1851 9.08296C1.04416 8.9057 0.97628 8.68136 0.99534 8.45578C1.0144 8.2302 1.11896 8.0204 1.28765 7.86926C1.3702 7.79716 1.46655 7.74258 1.57085 7.70881C1.67515 7.67505 1.78524 7.6628 1.89442 7.67283C2.0036 7.68285 2.10961 7.71493 2.206 7.76712C2.3024 7.81931 2.38717 7.89053 2.45519 7.97644L6.38931 12.8723C6.68119 13.2364 6.63623 13.7817 6.28596 14.086C6.20337 14.158 6.10698 14.2125 6.00264 14.2462C5.89831 14.2799 5.78821 14.2921 5.67903 14.282C5.56985 14.2719 5.46385 14.2398 5.36749 14.1875C5.27112 14.1352 5.18639 14.064 5.11843 13.978V13.9796Z" fill="#D1E0D5"/>
<path d="M5.29089 13.9123C5.12138 13.7619 5.01578 13.5525 4.99569 13.3269C4.9756 13.1013 5.04254 12.8765 5.18282 12.6986L14.5357 1.02417C14.6034 0.937911 14.6879 0.866297 14.7841 0.813674C14.8803 0.761051 14.9863 0.728519 15.0955 0.718052C15.2047 0.707585 15.3148 0.719403 15.4193 0.752787C15.5238 0.786171 15.6204 0.840425 15.7032 0.912254C16.0535 1.21568 16.1024 1.76106 15.8113 2.12596L6.45764 13.8043C6.38997 13.8906 6.30546 13.9622 6.20924 14.0148C6.11301 14.0675 6.00709 14.1 5.89789 14.1105C5.7887 14.1209 5.67852 14.1091 5.57403 14.0757C5.46955 14.0423 5.37295 13.9881 5.2901 13.9163V13.9115L5.29089 13.9123Z" fill="#D1E0D5"/>
</svg>

const CrossSvg = () => <svg width="16" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="1.19141" y1="18.5233" x2="18.162" y2="1.55272" stroke="#F1F5FF" strokeWidth="2" strokeLinecap="round"/>
<line x1="1.41421" y1="1" x2="18.3848" y2="17.9706" stroke="#F1F5FF" strokeWidth="2" strokeLinecap="round"/>
</svg>
